<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="dataFromDb"
      :search="search"
      :loading="loading"
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-8">
            <div class="container">
              <v-dialog
                v-model="isVisibleCreateDialog"
                persistent
                width="600"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="addTrackingCode"
                  >
                    {{ $t('TrackingCodeElements.BtnAdd') }}
                  </v-btn>
                </template>

                <v-form ref="form">
                  <v-card>
                    <v-card-title>
                      {{ $t('TrackingCodeElements.HeaderTextAdd') }}
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>{{ $t('TrackingCodeElements.Domain') }}</label>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-text-field
                            v-model="url"
                            outlined
                            hide-details
                            :rules="[required, urlValidator]"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>{{ $t('TrackingCodeElements.Advertiser') }}</label>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-autocomplete
                            v-model="advertiser"
                            :items="advertisers"
                            item-text="name"
                            item-value="id"
                            :disabled="loadingFirst"
                            hide-details
                            outlined
                            dense
                            return-object
                            :rules="[requiredNotText]"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        @click="cancel"
                      >
                        {{ $t('BtnCancel') }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="customValidate"
                      >
                        {{ $t('BtnSave') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>

              <v-dialog
                v-model="isVisibleViewDialog"
                width="600"
              >
                <v-card>
                  <v-card-title>
                    {{ $t('TrackingCodeElements.HeaderTextView') }}
                  </v-card-title>

                  <v-card-text>
                    <prism>
                      {{ pixel }}
                    </prism>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="info"
                      :href="`files/install_pixel_${$i18n.locale}.docx`"
                    >
                      {{ $t('TrackingCodeElements.BtnInstruction') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-tooltip
                      v-model="isTooltipVisible"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        ></v-icon>
                      </template>
                      <span>{{ $t('TrackingCodeElements.TooltipCopyClipboard') }}</span>
                    </v-tooltip>
                    <v-btn
                      outlined
                      color="success"
                      @click="copyText"
                    >
                      {{ $t('TrackingCodeElements.BtnCopyClipboard') }}
                    </v-btn>
                    <v-btn
                      outlined
                      color="error"
                      @click="isVisibleViewDialog = false"
                    >
                      {{ $t('BtnClose') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="isVisibleEditDialog"
                width="800"
              >
                <v-card>
                  <v-card-title>
                    {{ $t('TrackingCodeElements.HeaderTextEdit') }}
                  </v-card-title>

                  <v-card-text>
                    <v-form>
                      <v-row>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>{{ $t('TrackingCodeElements.Domain') }}</label>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-text-field
                            outlined
                            dense
                            disabled
                            :value="domain"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>{{ $t('TrackingCodeElements.Advertiser') }}</label>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-text-field
                            outlined
                            dense
                            disabled
                            :value="advertiserName"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>{{ $t('TrackingCodeElements.Status') }}</label>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-autocomplete
                            v-model="selectedStatus"
                            :items="statuses[$i18n.locale]"
                            item-text="name"
                            item-value="key"
                            hide-details
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>{{ $t('TrackingCodeElements.Pixel') }}</label>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <prism>
                            {{ pixel }}
                          </prism>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="saveEditedTrackingCode"
                    >
                      {{ $t('BtnSave') }}
                    </v-btn>
                    <v-btn
                      outlined
                      color="error"
                      @click="isVisibleEditDialog = false"
                    >
                      {{ $t('BtnClose') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="isVisibleDeleteDialog"
                width="400"
              >
                <v-card>
                  <v-card-title>
                    {{ $t('TrackingCodeElements.HeaderTextDelete') }}
                  </v-card-title>

                  <v-card-text>
                    {{ $t('TrackingCodeElements.TextDeleteConfirm') }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="deleteTrackingCode"
                    >
                      {{ $t('BtnYes') }}
                    </v-btn>
                    <v-btn
                      outlined
                      color="error"
                      @click="isVisibleDeleteDialog = false"
                    >
                      {{ $t('BtnNo') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div class="col-4">
            <v-text-field
              v-model="search"
              :label="$t('LblSearch')"
              class="mx-4"
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:item="row">
        <tr>
          <td>
            <a
              :href="`https://${row.item.domain}`"
              target="_blank"
            >{{ row.item.domain }}</a>
          </td>
          <td>{{ row.item.user.name }}</td>
          <td>
            <v-chip
              class="ma-2"
              :color="row.item.isActive? 'success' : 'error'"
              text-color="white"
            >
              {{ row.item.isActive ? $t('TrackingCodeElements.Active') : $t('TrackingCodeElements.InActive') }}
            </v-chip>
          </td>
          <td>
            <template>
              <v-row>
                <v-col>
                  <v-menu
                    top
                    offset-x
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="showPixel(row.item.domain, row.item.user.appDomains.domain, row.item.id)">
                        <v-list-item-icon class="mr-3">
                          <v-icon>
                            {{ icons.mdiEyeOutline }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('TrackingCodeElements.BtnView') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="editTrackingCode(row.item.id, row.item.domain, row.item.user.appDomains.domain, row.item.user.name, row.item.isActive)">
                        <v-list-item-icon class="mr-3">
                          <v-icon>
                            {{ icons.mdiPencil }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('TrackingCodeElements.BtnEdit') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="showDeleteDialog(row.item.id)">
                        <v-list-item-icon class="mr-3">
                          <v-icon>
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('TrackingCodeElements.BtnDelete') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { integerValidator, required, requiredNotText, requiredSelect, urlValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencil } from '@mdi/js'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import qs from 'qs'
import Prism from 'vue-prism-component'

export default {
  setup() {
    const url = ref('')
    const advertiser = ref('')
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    return {
      url,
      advertiser,
      validate,
      form,
      required,
      urlValidator,
      integerValidator,
      requiredSelect,
      requiredNotText,
    }
  },
  components: {
    Prism,
  },
  data() {
    return {
      search: '',
      dataFromDb: [],
      loading: false,
      isVisibleCreateDialog: false,
      isVisibleViewDialog: false,
      isVisibleEditDialog: false,
      isVisibleDeleteDialog: false,
      pixel: '',
      headers: [],
      isOutlined: true,
      isTooltipVisible: false,
      icons: {
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencil,
        mdiDeleteOutline,
      },
      id: '',
      domain: '',
      advertiserName: '',
      selectedStatus: '',
      statuses: {
        en: [
          {
            name: 'Active',
            key: 'active',
          },
          {
            name: 'Inactive',
            key: 'inactive',
          },
        ],
        ru: [
          {
            name: 'Активна',
            key: 'active',
          },
          {
            name: 'Не активна',
            key: 'inactive',
          },
        ],
      },
      loadingFirst: false,
      advertisers: [],
      trackingCode:
        '<script type="text/javascript">\n' +
        '    (function() {\n' +
        "        var s = document.createElement('script');\n" +
        "        s.type = 'text/javascript';\n" +
        '        s.async = true;\n' +
        "        s.src = 'https://[[domain]]/t/tr/cnt?condition=[[condition]]&document_url='+ document.URL;\n" +
        "        var d = document.getElementsByTagName('script')[0];\n" +
        '        d.parentNode.insertBefore(s, d);\n' +
        '    })();\n' +
        '<' +
        '/' +
        'script>',
    }
  },
  mounted() {
    this.getDataFromDB()
  },
  mounted() {
    this.getDataFromDB()
  },
  methods: {
    getDataFromDB() {
      this.loading = true

      this.$http
        .get(`${this.$apiBaseURL}/tracking-code?userId=${this.$getUserId()}`)
        .then(response => {
          this.dataFromDb = response.data != null ? response.data : []

          this.headers = []

          this.headers.push({ text: this.$t('TrackingCodeElements.Domain'), value: 'domain' })
          this.headers.push({ text: this.$t('TrackingCodeElements.Advertiser'), value: 'user.name' })
          this.headers.push({ text: this.$t('TrackingCodeElements.Status'), value: 'isActive' })
          this.headers.push({ text: this.$t('TrackingCodeElements.Action'), value: '' })

          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    addTrackingCode() {
      this.getAdvertisers()
    },

    saveTrackingCode(domain, user) {
      this.loading = true
      this.isVisibleCreateDialog = false

      const params = {
        domain,
        userId: user.id,
      }

      this.$http
        .post(`${this.$apiBaseURL}/tracking-code`, params)
        .then(response => {
          this.showPixel(response.data.domain, user.appDomains.domain, response.data.id)
          this.getDataFromDB()
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    customValidate() {
      this.validate()
      if (urlValidator(this.url) === true && required(this.url) === true && requiredNotText(this.advertiser) === true) {
        this.isVisibleCreateDialog = false
        this.saveTrackingCode(this.url, this.advertiser)
        this.url = ''
        this.advertiserId = ''
      }
    },

    getAdvertisers() {
      this.loadingFirst = true

      return this.$http
        .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.advertisers = response.data
          if (!this.advertiser) {
            if (this.advertisers.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.advertiser = this.advertisers[0]
            }
          }

          this.loadingFirst = false
        })
    },

    cancel() {
      this.isVisibleCreateDialog = false
      this.url = ''
    },

    replacePlaceHolders(pixel, condition, domain) {
      let replaced = ''
      replaced = pixel.replaceAll('[[condition]]', btoa(condition))
      replaced = replaced.replaceAll('[[domain]]', domain)

      return replaced
    },

    showPixel(domain, appDomain, id) {
      const condition = `domain=${domain}&id=${id}`
      this.pixel = this.replacePlaceHolders(this.trackingCode, condition, appDomain)
      this.isVisibleViewDialog = true
    },

    editTrackingCode(id, domain, appDomain, advertiserName, isActive) {
      this.id = id
      this.domain = domain
      this.advertiserName = advertiserName
      this.selectedStatus = isActive ? this.statuses[this.$i18n.locale][0].key : this.statuses[this.$i18n.locale][1].key

      const condition = `domain=${domain}&id=${id}`
      this.pixel = this.replacePlaceHolders(this.trackingCode, condition, appDomain)

      this.isVisibleEditDialog = true
    },

    saveEditedTrackingCode() {
      const isActive = this.selectedStatus === 'active'
      const params = {
        id: this.id,
        isActive,
        domain: this.domain,
      }

      this.$http
        .put(`${this.$apiBaseURL}/tracking-code`, params)
        .then(() => {
          this.getDataFromDB()
        })
        .catch(error => {
          console.log(error)
        })
      this.isVisibleEditDialog = false
    },

    showDeleteDialog(id) {
      this.id = id
      this.isVisibleDeleteDialog = true
    },

    deleteTrackingCode() {
      const params = {
        id: this.id,
      }

      this.$http
        .delete(`${this.$apiBaseURL}/tracking-code?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
        .then(() => {
          this.getDataFromDB()
        })
        .catch(error => {
          console.log(error)
        })

      this.isVisibleDeleteDialog = false
    },

    copyText() {
      navigator.clipboard.writeText(this.pixel)
      this.isTooltipVisible = true
      setTimeout(() => {
        this.isTooltipVisible = false
      }, '1000')
    },
  },
}
</script>
